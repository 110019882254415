import { Typography } from "@mui/material";
import React, { useState } from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import {
  deliveryResponseJson,
  listOrderEndpoints,
  deliveryJsonExample,
  deliveryResponseJson2,
} from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import {
  codeFont,
  listStyle,
  listItems,
  pageStyles,
  pageContent,
  textHeader,
  body,
} from "../../styleConstants";
import PageTitle from "../../components/PageComponents/PageTitle";
import VersionSwitch from "../../components/VersionSwitch";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };

  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={9} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Data Delivery" />
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />
          <Typography variant="body1" style={body}>
            Once the data has been uploaded to the GLOBHE marketplace and
            quality checked by an admin, it will be ready for collection. The
            client will be notified by email that the status of the order is
            complete and that the data can be fetched. Alternatively, the client
            can also be notified by webhook if the a webhook url was inserted in{" "}
            <code style={codeFont}>notification</code>.
          </Typography>
          <Typography variant="body1" style={body}>
            Data will be delivered as single files which the client can download
            and save to their server of choice. The files are stored in an array
            located in <code style={codeFont}>deliveryFiles</code> .
          </Typography>
          <Typography variant="body1" style={body}>
            You can choose the <code style={codeFont}>fileDataType</code> to
            receive in the response by adding this filter in the request body.
            This is helpful if you only want to receive the 2D orthomosaics maps
            for example and not the baseFiles. If you don&apos;t specify, all
            the delivery files available in the order will be returned.
          </Typography>
          <ul style={listStyle}>
            <li style={listItems}>
              <code style={codeFont}>{listOrderEndpoints[0].code}</code> :{" "}
              {listOrderEndpoints[0].description}
            </li>
          </ul>
          <Typography variant="h5" style={textHeader}>
            Example Request
          </Typography>
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorder/123456789" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorder/123456789" />
          )}
          <Typography variant="h5" style={textHeader}>
            Example Response
          </Typography>
          <JsonContainer text={deliveryResponseJson} />
          <Typography variant="h5" style={textHeader}>
            Example Request with fileDataType specified
          </Typography>
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorder/123456789" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorder/123456789" />
          )}
          <JsonContainer text={deliveryJsonExample} />
          <Typography variant="h5" style={textHeader}>
            Example Response
          </Typography>
          <JsonContainer text={deliveryResponseJson2} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
